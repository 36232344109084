.inputStyle::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.inputStyle:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.inputStyle::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
.mainDiv {
  padding: 8px 20px;
  text-align: center;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}
.heading {
  text-align: center;
  color: #07154c;
  font-weight: 600;
  font-size: 1.5rem;
  font-family: monospace;
  text-transform: capitalize;
  /* text-decoration: underline; */
}
.sub_heading {
  color: #444;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-family: monospace;
}
.next_subheading {
  font-size: 1rem;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 6px;
  color: #111;
  margin-right: 13px;
  border: 1px solid;
  font-family: monospace;
}
.next_subheading:hover {
  background-color: #3f5efb;
  color: #fff;
  border: none;
}
.mtables {
  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
}
.resultDiv {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.resultImageDiv {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  height: 250px;
}
.labelDiv {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
  text-align: start;
}
.labelStyle {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: monospace;
}
.resultImageDiv {
  margin-bottom: 25px;
}
.inputDiv {
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 0px;
  width: 60%;
}

.inputStyle__type__date {
  background:rgb(218, 222, 225);
  color: white;
  width: 100%;
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  padding: 7px 10px;
  border-radius: 4px;

}
.inputStyle {
  background-color: #3f5efb;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  padding: 7px 10px;
  border-radius: 4px;

}
@media only screen and (max-width: 600px) {
  .inputStyle {

    height: auto;
  }
}
.buttonDiv {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.button {
  width: 120px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color:#3f5efb;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 7px;
}
.button:hover {
  background-color:#3f5efb;
}
.inputImageStyle {
  width: 100%;
  height: 80%;
  object-fit: contain;
}
.uplaodDiv {
  width: 100%;
  display: flex;
}
.input__form {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: 10px;
}
.link__tag {
  font-family: monospace;
  font-size: 1.2rem;
}
.uploadInputStyle {
  width: 38%;
  outline: none;
  border: none;
  font-size: 10px;
  background-color: #3f5efb;
  background: #fff;
  border: 2px solid #3f5efb;
  border-radius: 0px;
}
.uploadBtn {
  width: 80px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #3f5efb;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0px;
}
.uploadBtn:hover {
  background-image: #3f5efb;
}

.APMCard {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100vh;
  flex-direction: column;
  overflow: hidden;
}
.APMCardSub {
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
    width:50%;
    height:100%;
    position: relative;
    overflow: hidden;
}
@media screen and (max-width:1024px) and (min-width:500px) {
    .APMCardSub {
        padding: 20px;
        width:70%;
        height:90%;
        border-radius: 25px;
    }
}
@media only screen and (max-width:500px) {
    .APMCardSub {
        width:100%;
        height:100%;
    }
}

.APUserLandingPageMainDiv {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height:100%;
}
.APLandingButtons{
  margin-bottom: 50px;
  color: #1e5597;
  background-color: #bff0cf;
  padding:10px 30px;
  cursor: pointer;
  font-weight: bold;
  font-size: medium;
  text-align: center;
}

.APHeader{
    color: #1e5597;
    padding:10px;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-direction: row;
}


.APhr-line{
    border-color: #1a67c4;
    background-color: #1a67c4;
    margin:10px;
    height:0;
}
.APform-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 15px;
}
.APform-div select,.APform-div textarea , .APform-div input , .APTimePicker select ,.APMultiSelect{
  width:90%;
  border: 0;
  font-size: 17px;
  background-color: white;
  border-bottom: 2px solid#1e5597;
  border-radius: 0;
  /* padding: 5px; */
  color: #1e5597;
  margin-top: 5px;
  font-weight: 500;
  margin-left: 20px;
  font-family: sans-serif;
}
.APform-div select:focus ,.APTimePicker select:focus {
    outline: none;
}
.APform-div input:focus , .APDatefilter:focus{
    outline: none;
}
.APform-div input[type="text"]::placeholder,.APform-div textarea::placeholder{
    color: #1e5597;
    padding-left: 3px;
}
.APform-div input[type="date"]::-webkit-inner-spin-button {
    opacity: 0
}


.APform-div input[type="date"]::-webkit-calendar-picker-indicator {
    background: url('icon/downicon.png') center/80% no-repeat;
    color: rgba(0, 0, 0, 0);
    opacity: 1;
}

.APform-div input[type="date"]::-webkit-calendar-picker-indicator:hover {
    background: url('icon/downicon.png') center/80% no-repeat;
    opacity: 1
}

.APiconandheading{
    width:20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.APtableContent{
    width:80%;
    align-items: flex-start;
}
::-webkit-scrollbar {
    display: none;
}

.APAppoinmentDetails{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
@media only screen and (max-width:310px) {
    .APAppoinmentDetails{
        flex-direction: column;
    }
} 
.APAppoinmentDetailsSub1{
    margin:10px;
}
.APAppoinmentDetailsSub1 div{
  padding:3px 0px 3px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width:100%;
}
.APDatefilterDiv , .APAppoinmentDetailsSub2 div {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
}
.APDatefilterDiv{
    justify-content:space-evenly;
}
.APAppoinmentDetailsSub2{
    margin-top: -5px;
    font-size: small;
}
.APLoader{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100% !important;
    height:50vh !important;
    opacity: 0.9;
}
.APDatefilter{
    background-color: rgb(158, 209, 241);
    border: none;
    padding: 3px 20px;
    border-radius: 50px;
}
.APUserAppointmentDetails{
    display:flex;
    align-items: center;
    justify-content: flex-start;
    color: #1e5597;
    font-weight: 500;
    margin:10px 0px 0px 20px
}
.APtableContent a{
    text-decoration: none;
}
.APDetailsname{
    display:flex;
    align-items: center;
    justify-content: flex-start;
    color: #1e5597;
    font-weight: bold;
    font-size: large;
    margin: 15px 0px 10px 20px;
}
.APA_booked , .APTimePicker{
    display: flex;
    align-items: center;
    justify-content: center;
}
.APA-booked_child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    color: #1e5597;
    background-color: #d4faf7;
    margin: 5px;
    padding:5px
}
.APA_booked_sub{
    margin-top: 10px;;
    width:90%;
    max-height: 35vh !important;
    overflow: auto;
}
.APTimePicker{
    margin-top: 25px;
    margin-left: -10px;
}
.APHeader img{
    width:30px;
    margin: 4px 20px 0px 0px;
}
.APMuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: none !important;
    transition: none !important;
    border-bottom: 2px solid #3f51b5;
    pointer-events: none;
}
.APsidemenu1 {
    position: absolute;
    top: 0px;
    width: 0px;
    bottom: 0px;
    left: 0px;
    right:0px;
    z-index: 1;
    overflow-x: hidden;
    transition: all .5s;
}

.APquiz-card{
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    height: 50vh;
    width:40%;
    cursor: context-menu;
    position: relative;
  }

  @media screen and (max-width:1024px) and (min-width:541px) {
    .APquiz-card{
        width:55%;
        height:300px;
      }
      .APsidemenu1 {
        border-radius:25px;
    }
}
@media only screen and (max-width:540px) {
    .APquiz-card{
        width:80%;
        height:300px;
      }
}
  .APdisabledSelect:disabled{
      opacity: 1 !important;
  }
  .APswitch{
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      border-bottom:1px solid lightgray;
      border-top:1px solid lightgray;
  }
  .APswitch div{
      padding:10px;
      font-size: 17px;
      font-weight: 700;
      color:rgb(30, 85, 151);
      cursor: pointer;
      width:33.333333333333336%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  }
  .APAddNotesMainDiv div{
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       padding:10px
  }
  .APAddNotesMainDiv div label{
      margin: 15px;
      color: #1e5597;
      font-weight: 500;
  }
  .APAddNotesMainDiv div textarea{
    width:90%;
    background-color: rgb(212, 250, 247);
    border:0;
    font-size: 18px;
    resize:vertical;
}
.APAddNotesMainDiv div textarea:focus{
    outline: none;
}
.APEachDiv:hover{
    cursor: pointer;
    transition: all .5s;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin: 10px;
}

.APHeaderSwitch{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding:10px 10px 20px 10px;
    font-size:22px;
    color: #1e5597;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
}

.APHeaderSwitch > div >span{
    padding: 0px 10px 8px 10px;
}

.APAdminParentMButton{
    display: flex;
    align-items:center;
    justify-content:center;
    width: 100%;
    position: absolute !important;
    bottom: 0px;
    background-color: white;
    padding-bottom: 10px;
    padding-top: 3px;
}

.APAdminParentMButton > span{
    background-color: #1e5597;
    font-weight: 600;
    padding: 3px 15px 5px;
    color: white;
    border-radius: 15px;
    cursor: pointer;
}

.APAdminParentMButton > span:hover{
    box-shadow: 0 4px 10px rgba(4, 0, 255, 0.5);
    zoom: 105%;
}

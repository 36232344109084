
textarea{
    border: 0;
    width: 95%;
    background: #dbfde6;
    border-radius: 10px;
    font-size: 15px;
    padding: 10px;
    resize:vertical;
    font-family:'Poppins';

}
textarea:focus{
    outline: none;
}

form span{
    /* background: #dbfde6; */
    display: flex;
    align-items: center;
    border-radius: 15px;
    margin-top: -10px;
    font-family:'Poppins';

}

span textarea{
    width: 89%;
    resize: none;
    font-family:'Poppins';
}

form span input{
    border: 0;
    background: rgba(0, 255, 255, 0);
    padding:7px 15px;
    font-size: 17px;
    font-family:'Poppins';
}
form select{
    border: 0;
    background: #dbfde6;
    border-radius: 7px;
    font-size: 14px;
    padding: 6px;
    color: teal;
}
form select:focus{
    outline: none;
}
#add-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: row !important;
    flex-wrap: wrap !important;
}
#sc-button{
    display: flex;
    align-items: center;
    float: right;
}

@media only screen and (max-width:319px){
   
    #add-button{
       justify-content: center;
       flex-direction: column;
   }
    #sc-button{
        float: unset;
    }
    
}
.closeButton{
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 9px;
    color: teal;
}

.popup button{
    border: 2px solid black;
    background-color: white;
    color: black;
    font-size: 20px;
    cursor: pointer;
    border-color: #2196F3;
  color: rgb(64, 131, 197);
  margin: 7px;
}

.popup button:hover {
    background: #2196F3;
    color: white;
  }
.popup button:focus{
    outline: none;
    zoom: 130%;
}

.spchButton , #loader{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.youSelected{
    background: #2196F3 !important;
    color: white !important;
    zoom: 140%;
}

.form-div input[type = "radio"]{
    width:18px;
    height:18px;
}
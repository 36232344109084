.main__filter {
  margin: 1rem;
  font-family: sans-serif;
  margin-bottom: 2rem;
}

.info__div {
  display: grid;
  grid-template-columns: auto auto auto auto;
  border: 2px solid lightskyblue;
  padding: 1rem;
  border-radius: 6px;
  margin-top: 0.5rem;
}
.date__div {
  display: grid;
}

.grid__div1 {
  grid-area: 1 / 1 / 4 / 3;
}
.grid__div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.grid__div3 {
  grid-area: 2 / 1 / 3 / 6;
}
.grid__div4 {
  grid-area: 3 / 1 / 4 / 2;
}
.grid__div5 {
  grid-area: 2 / 2 / 3 / 3;
}
.grid__div6 {
  grid-area: 3 / 2 / 4 / 3;
}
.grid__div5 input{
  margin-bottom: 0.3rem;
}

.date__div input{
  border-radius: 3px;
  border: 1px solid black;
  padding: 0.3rem;
}
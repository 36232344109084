@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
}
h3 {
  color: #1565c0;
}
/* div {
  margin: 5px 0px;
} */
label {
  font-weight: 500;
}
.form {
  margin: 10px 15px;
}
.form-main {
  margin: 0px 20px;
  margin-top: 20px;
}
.form-heading {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  background-color: #42a0ea;
}
.form-heading > h3 {
  color: white;
  margin-top: 8px;
}
.form-details,
.form-subject-details {
  display: flex;
}
.form-details > div {
  width: 100%;
}
.form-details > div > input {
  width: 50%;
  padding: 3px 8px;
  margin-left: 5px;
  outline: none;
  border: none;
  background-color: white;
  border-radius: 0px;
  border-bottom: 1px solid black;
}
.form-subject-details > div {
  width: 100%;
}
.form-subject-details > div > input {
  width: 50%;
  padding: 3px 8px;
  margin-left: 5px;
  outline: none;
  border: none;
  border-radius: 0px;
  background-color: white;
  border-bottom: 1px solid black;
}
.lesson-name > input {
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0px;
  background-color: white;
  border-bottom: 1px solid black;
}
.user-details {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 18px;
}
@media screen and (max-width: 600px) {
  .form-details,
  .form-subject-details {
    margin: 0px;
    flex-direction: column;
  }
  .form-details > div {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .form-subject-details > div {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  select {
    margin-left: 10px;
    font-size: 15px;
  }
  .form {
    margin: 10px 0px;
  }
}
.form-supplemental {
  margin-top: 20px;
  margin-bottom: 5px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 14px;
}
.form-supplemental > h3 {
  margin-bottom: 12px;
  text-align: center;
}
.mid-details {
  margin-top: 20px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 14px;
}
.mid-details > div > label {
  margin-left: 5px;
}
.btn-div {
  display: flex;
  justify-content: center;
}
.select {
  color: black;
  margin-left: 10px;
  background-color: white;
  font-size: 16px;
}

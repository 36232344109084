.main{
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding:0.5rem 1rem;
  border-bottom: 2px solid skyblue;
  font-family: sans-serif;
  text-align: center;
  width: 100%;
  background-color: skyblue;
}

.name{
  /* background-color: rebeccapurple; */
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;

}
.name p{
  /* width: 100%; */
}
.name__wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.amount{
  display: flex;
  align-items: center;
  width: 25%;

}


.datetimepurpose{
  
  width: 50%;
  /* background-color: yellowgreen; */

}
.datetimepurpose p:nth-child(2){
  margin-top: -1rem;
}
.txnid{
  display: flex;
  align-items: center;
  width: 25%;
}
.txnid p {
  width: 100%;
}
.amount p{
  margin-bottom: -2px;
}


.name img{
  margin-right: 2rem;
  /* margin-left: -2rem; */
}

.btn{
  margin-right: 1rem;
  position: relative;
}
.btn button{
  background-color: skyblue;
  border-radius: 10px;
  color:black;
  padding: 0.3rem 1.3rem;
  cursor: pointer;
  border: none;
  font-weight: bold;
}

.action__popup{
  flex-direction: column;
  justify-content: space-evenly;
  background-color: blue;
  position: absolute;
  left: -55px;
  top: 31px;
  z-index: 2;
  width: 9.5rem;
  height: 5.5rem;
  border-radius: 2px;
  color: #fff;
  padding-left: 10px;
  font-weight: 700;
  border: 3.5px solid skyblue;
}

.action__popup p{
  cursor: pointer;
}


.action__popup p:hover{
  color:black;
}

@media (max-width:450px){
  .main{
      padding:0.5rem 0rem 0.5rem 1rem;

  }
  .name img{
    margin-right: 0.5rem;
    /* margin-left: -2rem; */
  }
  .name{
    align-items: flex-start;
  }
}
@media (max-width:400px){
  .main{
      padding:0.5rem 0rem 0.5rem 1rem;
  }
  .name img{
    margin-right: 0.5rem;
    /* margin-left: -2rem; */
  }

}
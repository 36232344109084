.download{
    margin: 10px;
    float: right;
}

#Users {
    font-family: 'Poppins';
    border-collapse: collapse;
    width: 100%;
}
  
  #Users td, #Users th {
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  #Users tr:nth-child(even){background-color: rgb(190, 187, 187)}
  #Users tr:nth-child(odd)
  {background-color: rgb(225, 223, 223)}
  
  #Users tr:hover {background-color: #ddd;}
  
  #Users th {
    padding :10px;
    padding-bottom: 5px;
    text-align: left;
    background-color: rgba(75, 75, 255, 0.653);
    color: white;
  }
  
  .quiz-list-table-div{
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    max-height: 79vh;
    min-height: 79vh;
    width: 100%;
    overflow: auto;
    box-sizing: unset !important;
}

@media only screen and (max-width:600px){
  .quiz-list-table-div{ 
    max-height: 100vh; 
    min-height: 100vh; 
    margin-bottom: 100px;     
}
}

.QM__Check__Download{
  display: flex;
  align-items:center;
  justify-content:space-between;
  flex-wrap: wrap;
  padding: 0px 10px 10px 0px;
}

.QM__Check__Button{
   padding: 2px 15px 5px;
   background-image:linear-gradient( 90.6deg , rgba(59, 158, 255, 1) -1.2%, rgba(246, 135, 255, 1) 91.6%);
   border-radius:25px;
   color: rgb(255, 255, 255);
   font-weight: 500;
   cursor: pointer;
}
.main span {
  background-color: skyblue;
  padding: 0.5rem 2rem;
  color: #000;
  border-radius: 4px;
  font-weight: 800;
}

.main img{
  display: block;
  height: 100px;
  width: 120px;
  margin: 1.5rem 0;
}

.main{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  text-align: center;
  font-weight: 800;
}

.wrapper input{
  background-color: skyblue;
}
.wrapper p{
  margin-top: 1rem;
}

.quicksearch{
  padding: 1rem 0 1rem 2rem;
  border-bottom: 2px solid skyblue;
}

.quicksearch span{
  font-weight: 800;
}
.quicksearch>span{
  background-color: skyblue;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.dateInput{
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}


@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
}
/* div {
  margin: 5px 0px;
} */
textarea {
  width: 100%;
  padding: 4px 8px;
  font-size: 16px;
  margin: 5px 0px;
  resize: none;
  border-radius: 10px;
}
input {
  width: 100%;
  padding: 4px 8px;
  font-size: 16px;
  margin: 5px 0px;
  border: 1px solid gray;
  border-radius: 10px;
}
label {
  font-weight: 600;
}
.form {
  margin: 10px 15px;
}
.form-main {
  margin: 0px 20px;
  margin-top: 20px;
}
.form-heading {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
}
.form-details,
.form-subject-details {
  display: flex;
}
.form-details > div {
  width: 100%;
}
.form-details > div > input {
  width: 50%;
  padding: 3px 8px;
  margin-left: 5px;
  outline: none;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid black;
}
.form-subject-details > div {
  width: 100%;
}
.form-subject-details > div > input {
  width: 50%;
  padding: 3px 8px;
  margin-left: 5px;
  outline: none;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid black;
}
#date {
  width: 170px;
}
.lesson-name > input {
  outline: none;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid black;
}
.user-details {
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 10px 18px;
}
@media screen and (max-width: 480px) {
  .form-details,
  .form-subject-details {
    margin: 0px;
    flex-direction: column;
  }
  .form-details > div {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .form-subject-details > div {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  select {
    margin-left: 10px;
    font-size: 15px;
  }
}
.store-details {
  margin-top: 20px;
  border: 1px solid gainsboro;
  border-radius: 10px;
  /* box-shadow: 6px 7px 22px -2px rgba(0, 0, 0, 0.75); */
  padding: 10px 14px;
}
.store-details > h3 {
  text-align: center;
  margin: 5px;
}
.daily-details {
  margin: 8px 0px;
}
.periods {
  width: 50px;
}
.accomplished {
  width: 80px;
}
.btn-div {
  display: flex;
  justify-content: center;
}
.accordion {
  margin-top: 20px;
}
.button {
  padding: 8px 14px;
  margin-top: 14px;
  font-size: 18px;
  background-color: black;
  color: white;
  border-radius: 5px;
}
.button:hover {
  background-color: rgb(46, 175, 226);
}
/* @media screen and (min-width: 1050px) {
  .store-details {
    display: none;
  }
}

@media screen and (max-width: 1050px) {
  .accordion {
    display: none;
  }
} */

.box {
  display: flex;
  align-items: center;
}
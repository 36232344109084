.wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: skyblue;
}

.main{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 38%;
  background: #fff;
  padding: 1rem 2.5rem;
  gap: 1.5rem;
}

.btn{
  margin-top: 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: blue;
  color: #fff;
  font-weight: 650;
  padding: 0.5rem 0;
}
@media (max-width: 1100px) {
  .main{
    width: 50%;
  }
}

@media (max-width: 900px) {
  .main{
    width: 75%;
  }
}
@media (max-width: 600px) {
  .main{
    width: 100%;
  }
}

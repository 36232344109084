form div{
  margin-bottom: 10px;
}

.form-div{
  display: flex;
  flex-direction: column;
 padding: 15px;
 background-color: white;
 box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
 border-radius: 7px;
}

.form-div input , .form-div select ,.form-div textarea{
  font-size: 17px;
  background-color: white;
  border-bottom: 2px solid rgba(0, 89, 243, 0.592);
  border-radius: 0;
  margin-top: 15px;
  font-family:'Poppins';
  padding: 5px;
  color:rgba(0, 0, 0, 0.721);
  font-weight: 300;
}


.form-div input::placeholder{
  opacity: 0.7;
}

label{
    color: rgb(0, 102, 255);
    font-weight: bold;
    font-family:'Poppins';
  }

.create-button{
  position: absolute;
  bottom: 2%;
  padding: 1% 8%;
  border: none;
  background-image: linear-gradient( 90.6deg,  rgba(59,158,255,1) -1.2%, rgba(246,135,255,1) 91.6% );
  border-radius: 2rem;
  color: #0066ff;
  font-weight: bold;
  font-family:'Poppins';
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.create-button:focus{
  outline: none;
}

input{
  border: 0;
  outline: none;
  background: #dbfde6;
  padding: 5px 20px;
  border-radius: 10px;
}


input:focus{
  outline: none;
}

input[type="date"], input[type="time"]{
  color: rgba(0, 0, 0, 0.85);
  font-weight: bolder;
}

.button-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-div select:disabled{
  opacity: 1;
}

.APsidemenu1 {
  position: absolute;
  top: 0px;
  width: 0px;
  bottom: 0px;
  left: 0px;
  right:0px;
  z-index: 1;
  overflow-x: hidden;
  transition: all .5s;
}

.APquiz-card{
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  height: 50vh;
  width:40%;
  cursor: context-menu;
  position: relative;
}

@media screen and (max-width:1024px) and (min-width:541px) {
  .APquiz-card{
      width:55%;
      height:300px;
    }
    .APsidemenu1 {
      border-radius:25px;
  }
}
@media only screen and (max-width:540px) {
  .APquiz-card{
      width:80%;
      height:300px;
    }
}
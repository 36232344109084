.main{
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  font-family: sans-serif;
  padding-top: 2rem;
  color: #fff;
}

.title{
  font-size: 60px;
  padding: 0 1.5rem;
}
.title img{
  width: 100%;
}

.number{
  font-size: 20px;
 padding: 1rem 0;
 color: #000
}
.input__div{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input__div input{
  width: 20rem;
  padding: 0.5rem;
  height: 32px;
  border-radius: 4px;
  border: none;
}
.input__div input:focus{
  outline: none;
  border: none;
}

.input__div button{
  margin-top: 1rem;
  display: block;
  width: 5rem;
  height: 2rem;
  color: #fff;
  font-weight: 700;
  border: none;
  background-color: #0093E9;
  cursor: pointer;
}
.card{
  position: relative;
}
.card img{
  width: 30rem;
  height: 15rem;
}
.card__para{
  position: absolute;
  bottom: 0;
  left: 2rem;
  font-size: 30px;
}
.main2__wrapper{
  background-color: #fff;
  padding: 2rem 0 0 0 ;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main2{
  padding-top: 1rem;
  display: flex;
  width: 32rem;
  background-color: #fff;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  font-family: sans-serif;
  color: #000;
}
.balance__div{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;

}
.transaction__div a{
  text-decoration: none;
  color: darkblue;
}

.balance__div p{
  font-size: 25px;
  color: darkblue;
}

.balance__div h1{
  font-size: 70px;
  color: darkblue;
}

.transaction__div{
  cursor: pointer;
  color: darkblue;
}

.btn{
  margin-top: 10rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: blue;
  color: #fff;
  font-weight: 650;
  padding: 0.5rem;
}
.btn1{
  width: 110px;

}
.btn__wrapper{
  align-items: end;
  justify-content: right;
  display: flex;
  width: 97%;
}
.btn p{
  margin: 0.5rem;
  color: #fff;
}

.btn-flexer{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media (max-width: 1100px) {
  .main{
    width: 50%;
  }
  .main2{
    width: 25rem;
  }
  
}

@media (max-width: 900px) {
  .main{
    width: 75%;
  }
}
@media (max-width: 600px) {
  .main{
    width: 100%;
  }
}

@media (max-width: 500px) {
  .main2{
    width: 16rem;
  }
  .btn{
    padding: 0.5rem 0;
    font-size: 9px;
    min-width: 80px;
  }
  .logout{
    margin-right: -6rem;
  }
  .card img{
    width: 23rem;
  }
}


@media (max-width: 450px) {
  .main2{
    width: 16rem;
  }
  .btn{
    padding: 0.5rem 0;
    font-size: 9px;
    min-width: 80px;
  }
  .logout{
    margin-right: -5rem;
  }
}



.main{
  margin: 1rem 0 0 0.5rem;
}

.load__div{
  display: flex;
  align-items: center;
}
.load__div p{
  background-color: skyblue;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  cursor: pointer;
  color: #000;
}
.report__div{
  display: flex;
  align-items: center;
}

.report__div img{
  height: 95px;
  width: 99px
}

.report__div p{
  background-color: skyblue;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  cursor: pointer;
  color: #000;
}

.search{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.search button{
  margin-left: 2rem;
  border-radius: 4px;
  background-color: skyblue;
  border: none;
  padding: 0.5rem 1rem;
  font-weight: 700;
  cursor: pointer;
}

.info__div{
  margin: 2rem 0;
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  gap: 5rem;
}

.flex{
  display: flex;
  align-items: center;
  gap: 2rem;
}

.flex img{
  height: 115px;
  width: 90px;
}

.load{
  display: flex;
  align-items: center;
  gap: 3rem;
  font-weight: 700;
}
.load p{
  font-size: 35px;
}

.form button{
  margin-left: 2rem;
  border-radius: 4px;
  background-color: skyblue;
  border: none;
  padding: 0.5rem 1rem;
  font-weight: 700;
  cursor: pointer;
}
.sidemenu1 {
    position: absolute;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    width: 0px;
    bottom: 0px;
    background: rgba(2, 51, 107, 0.7);
    z-index: 1;
    padding-top: 100px;
    overflow-x: hidden;
    transition: all .5s;
}

.quiz-container {
    background: rgb(239, 241, 241);
    min-height: 100vh;
    max-height: 100vh;
    width: 45%;
    padding: 2% 2.5%;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
    position: relative;
    border: 2px solid rgb(239, 241, 241);
    text-align: start;
}

@media screen and (max-width:1024px) and (min-width:600px) {
    .quiz-container {
        width: 90%;
        padding-top:20px ;
    }
}

@media screen and (max-width:1250px) and (min-width:1024) {
    .quiz-container {
        width: 55%;
    }
}

@media only screen and (max-width:600px) {
    .quiz-container {
        height: 100vh;
        width: 100%;
        overflow: hidden;
    }
}

::-webkit-scrollbar {
    display: none;
}

.quiz-head {
    color: rgba(4, 44, 173, 0.856);
    font-weight: bolder;
    font-size: 1.65rem;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.quiz-head-title , .quiz-title {
    white-space: nowrap;
    overflow-x: scroll;
}

.hr-line {
    margin: 3% 1% 3% 0%;
    height: 5px;
    border: 0;
    border-radius: 2rem;
    background-image: linear-gradient( 90.6deg, rgba(59, 158, 255, 1) -1.2%, rgba(246, 135, 255, 1) 91.6%);
}

#navlink {
    text-decoration: none;
    cursor: pointer;
}

#loader {
    margin: 20%;
}


.sidemenu {
   display: flex;
   align-items: flex-end;
   flex-direction: row;
}

.sidemenu img{
    height:23px;
    color: rgba(4, 44, 173, 0.856);
    padding-left: 10px;
    padding-right: 10px;
}

.swal2-popup *{
    box-sizing: unset !important;
}

.search-div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-search{
    border: 0;
  border-radius: 20px;
  background-image: linear-gradient( 112.7deg,  rgba(162,247,241,1) 0.3%, rgba(199,146,255,1) 88.7% );
  height: 3vh;
  width: 30%;
  font-size: 16px;
  text-align: center;
  padding:13px;
}
@media only screen and (max-width:1024px){
    .quiz-search{
        width: 50%;
        padding:13px;
    }
}
.quiz-search:focus {
   outline: none;
   padding:13px;
  }
.quiz-list{
    overflow: hidden;
}
.quiz-list-items{
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    max-height: 73vh;
    width: 100%;
    overflow: auto;
    box-sizing: unset !important;
}
@media only screen and (max-width:600px){
  .quiz-list-items{ 
    max-height: 77vh; 
    margin-bottom: 100px;     
}
}
.quiz-card{
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  transition: all .5s;
}
.quiz-title{
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.quiz-options{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    position: relative;
    padding: 5px;
}
.quiz-option{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
  position: relative;
}

.quiz-edit{
    color: rgba(0, 0, 128, 0.733);
    margin: 13px;
    font-family:'Poppins';
    cursor: pointer;
   } 
.quiz-view-results {
    color: rgba(255, 0, 64, 0.705);
    cursor: pointer;
    font-family:'Poppins';

}

.quiz-create-new{
    position: absolute;
    bottom: 2%;
    font-family:'Poppins';
    right: 1%;
}

.quiz-notfound{
    text-align: center;
    margin-top: 100px;
    font-family:'Poppins';

}

.quiz-options-edit{
    position: absolute;
    bottom: 2%;
    left: 1%;
    font-family:'Poppins';
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 0, 0, 0.705);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  #quizStatusCheck:checked + .slider {
    background-color: rgb(14, 194, 14);
  }
  
  #quizStatusCheck:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  #quizStatusCheck:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }


  .quiz__progress__bar {
    border-radius: 15px;
    width: 100%;
    transition: all 1s;
    position: relative;
    overflow: hidden;
}

.quiz__progress__colorbar {
    background-color: rgb(48, 255, 7);
    border-radius: 15px;
    transition: all 1s;
    position: absolute;
    height:20px;
    z-index: 1;
}
.quiz__progress__percentbar{
  border-radius: 15px;
  transition: all 1s;
  position: absolute;
  background-color: rgba(172, 172, 172, 0.5);
  text-align: center;
  width: 100%;
  height: 20px;
  color:rgb(255, 255, 255);
  z-index: 1;
  font-size: 14px;
}

.quiz__validate__next{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0px 0px;
}
.quiz__validate__next > input{
  padding: 7px;
  width: 70%;
  background-color:rgb(255, 255, 255);
  border: 2px solid rgba(228, 228, 228, 0.822);
  border-radius:100px;
}

.quiz__validate_next_button{
  background: rgba(4, 44, 173, 0.8); 
  padding: 2px 15px 4px; 
  border-radius: 25px;
  cursor: pointer;
  color: white;
}

.quiz__progress__main{
  background-color:rgb(255, 255, 255);
  padding:1px 5px 10px;
  border-radius:10px;
  overflow:scroll;
}

.quiz__validate__qdiv,.quiz__validae__adiv{
  background-color:white;
  padding:6px 6px;
  border-radius:10px;
  margin-top: 6px;
  width: 100%;
 
}

.quiz__validae__adiv{
  color: rgba(4, 44, 173, 0.855);
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.quiz__student__details{
  margin: 8px 0px 6px;
}

.quiz__card__textarea{
  width:100%;
  background-color:white;
  font-family:'Poppins';

}

.QMquiz_EditButton{
  padding: 5px;
  transition: .5s;
}

.QMquiz_EditButton:hover{
  padding: 5px 10px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}
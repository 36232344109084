.s1 {
  height: 30px;
  border: none;
  padding: 5px;
  border-radius: 4px;
  background: #fff;
}

.btnP {
  width: 90px;
  height: 30px;
  margin-left: 15px;
  margin-top: 11px;
  border: none;
  border-radius: 4px;
  background: #303f9f;
  color: #fff;
}

::-webkit-scrollbar {
  display: none;
}
.totalTicketingModule * {
  box-sizing: unset !important;
}
.totalTicketingModule h4 {
  font-size: 1em !important;
  font-weight: 500 !important;
}

.totalTicketingModule input {
  font-weight: unset !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  color: black !important;
  line-height: unset !important;
  background-color: none !important;
}

.excelReport {
  border: none;
  background-color: #3182ce;
  color: #fff;
  padding: 10px 30px;
  border-radius: 2px;
}

.totalTicketingModule select,
.totalTicketingModule label {
  color: black !important;
}
/* remove it */

.APsidemenu1 {
  position: absolute;
  top: 0px;
  width: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  overflow-x: hidden;
  transition: all 0.5s;
}

.APquiz-card {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  height: 50vh;
  width: 40%;
  cursor: context-menu;
  position: relative;
}

@media screen and (max-width: 1024px) and (min-width: 541px) {
  .APquiz-card {
    width: 55%;
    height: 300px;
  }
  .APsidemenu1 {
    border-radius: 25px;
  }
}

@media only screen and (max-width: 540px) {
  .APquiz-card {
    width: 80%;
    height: 300px;
  }
}

/* Header Page */

.TMHeader {
  background-color: #022a79;
  padding: 13px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.TMHeaderTitle {
  font-size: 21px;
  font-weight: bold !important;
  cursor: pointer;
}

.TMHeaderTitle:hover {
  zoom: 103%;
}

.TMHeaderOption {
  cursor: pointer;
  padding: 3px;
  margin: 0px 10px;
}

.TMHeaderOption:hover {
  background-color: rgba(71, 184, 255, 0.26);
  padding: 3px 7px;
  border-radius: 5px;
  transition: all 0.5s;
}

.TMHeaderOptions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.TMHamberger {
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.TMHeaderHamberger {
  background-color: #122854;
  color: white;
  text-align: center;
  position: absolute;
  width: 0;
  right: 0;
  z-index: 11;
  overflow-y: hidden;
  transition: all 0.5s;
  display: none;
}

.TMHeaderMobile {
  cursor: pointer;
  padding: 3px;
  padding: 10px;
}

.TMHeaderMobile:hover {
  background-color: rgba(71, 184, 255, 0.26);
  zoom: 105%;
  transition: all 0.5s;
}

@media only screen and (max-width: 600px) {
  .TMHamberger {
    display: block;
  }
  .TMHeaderOptions {
    display: none;
  }
  .TMHeaderHamberger {
    display: block;
  }
}

/*Welcome User */
.user__name {
  margin-top: 20px;
  margin-left: 14px;
  font-style: italic;
  font-size: 1.2rem;
}
.custom__date_range {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.custom__date_btn {
  border: none;
  height: 31px;
  padding: 6px 2px;
  background-color: #ade6e6;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.custom__input_date {
  height: 35px;
  background-color: #ade6e6;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border: none;
}

/* Request Ticket Page */

.TMRequestMainDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.TMRequestSubMainDiv1 {
  width: 40%;
}

.TMRequestSubMainDiv2 {
  width: 60%;
}

.TMRequestSubMainDiv1,
.TMRequestSubMainDiv2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TMRequestSubMainDiv1 div,
.TMRequestSubMainDiv2 div {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 80%;
}

.TMRequestSubMainDiv1 div label,
.TMRequestSubMainDiv2 div label {
  margin-bottom: 15px;
  font-weight: 600 !important;
}

.TMRequestSubMainDiv2 textarea,
.TMRequestSubMainDiv2 input,
.TMRequestSubMainDiv1 select {
  width: 90%;
  /* background-color: #fdf62dcb; */
  background-color: #ade6e6;
  border: 0;
  resize: vertical;
  border-radius: 5px !important;
  padding: 5px;
  font-size: 17px;
}

.TMRequestSubMainDiv2 div textarea:focus,
.TMRequestSubMainDiv2 div input:focus,
.TMRequestSubMainDiv1 div select:focus {
  outline: none;
}

.TMRequestSubMainDiv1 input::-webkit-file-upload-button {
  /* background-color: #fdf62dcb; */
  background-color: #ade6e6;
  border: 0;
  padding: 7px 10px 5px;
  border-radius: 5px;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .TMRequestMainDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .TMRequestSubMainDiv1 {
    width: 100%;
  }
  .TMRequestSubMainDiv2 {
    width: 100%;
  }
  .TMRequestSubMainDiv1 div,
  .TMRequestSubMainDiv2 div {
    margin: 10px;
    width: 90%;
  }
}

.TMSubmitButton {
  background-color: #122854;
  padding: 5px 20px;
  color: white;
  border-radius: 7px;
  cursor: pointer;
}

.TMSubmitButton:hover {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.425);
  zoom: 102%;
  transition: all 0.5s;
}

/* Ticket Reports Page  */

.TMAR__No_ReportsTitle {
  width: 100vw;
  height: 100vh;
}

.TMTicketReportTableMainDiv,
.TMTicketReportTableDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .TMTicketReportTableMainDiv,
  .TMTicketReportTableDiv {
    display: block !important;
  }
  .TMTicketReportTable {
    margin-top: 50px;
  }
}

.TMTicketReportTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
  overflow: scroll;
  /* border-radius: 20px; */
  background: #fff;
  /* border: 1px solid #dadce0; */
  cursor: pointer;
  border-collapse: separate;
  border-spacing: 0 0.5em;
  /* box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3); */
}

.TMMobileOptionsBackGround {
  width: 100%;
  height: 20px;
}

@media only screen and (max-width: 500px) {
  .TMMobileOptionsBackGround {
    width: 100%;
    height: 45px;
  }
}
@media only screen and (min-width: 1000px) {
  .TMTicketReportTable {
    width: 1000px;
  }
}

.TMTicketReportTable th,
.TMTicketReportTable td {
  border-bottom: 1.5px solid #dadce0;
  text-align: left;
  padding: 15px;
  font-weight: 400;
  white-space: nowrap;
  /* float: left; */
}

.TMTicketReportTable tr:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.TMTicketReportTable th {
  cursor: pointer;
  font-weight: 700;
  /* color: #fff; */
  background: rgba(255, 255, 255, 0.445);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  border-bottom: 2px solid #dadce0;
  margin-bottom: 30px;
  position: sticky;
  top: -1px;
  background-color: white;
}
.TMTicketReportTable th > div {
  /* display: flex;
  align-items: center; */
  text-align: center;
}

.TMTicketReportTable th > div > img {
  width: 20px;
  margin-right: 5px;
}

.TMTrackButton {
  /* background-color: #fdf62dcb; */
  background-color: #ade6e6;
  padding: 2px 15px;
  cursor: pointer;
  font-weight: 600 !important;
}

.TMTrackButton:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}

.TMSearch {
  border: 0px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 7px 20px;
  margin-top: 10px;
  background-color: #ade6e6;
  /* background-color: #fdf62dcb; */
}

.TMLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 50vh !important;
  opacity: 0.9;
}

.TMReportDetails {
  padding: 5px 20px;
  width: 100%;
  font-weight: 500 !important;
}

.TMReportDetails textarea {
  width: 95%;
}

.TMReplyLeftUpdateFile {
  display: flex;
  align-items: center;
  width: 85%;
  background-color: #ade6e6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  padding: 3px 5px;
}

.TMReplyLeftUpdateFile img {
  margin-bottom: 3px;
}

.TMReplyLeftUpdateFile input:hover {
  cursor: pointer;
  zoom: 104%;
}

.TMReplyLeftUpdateFile input::-webkit-file-upload-button {
  background-color: #ade6e6;
  border: 0;
  padding: 0px;
  border-radius: 5px;
  font-size: 0px;
}

/* Admin Dashboard Page */

.TMDashboardMainDiv {
  width: 1000px;
}

.TMAdminFilterDiv select,
.TMAdminSearch {
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border: 0px;
  padding: 10px;
  background-color: #ade6e6;
}

.TMAdminFilterDiv select:focus,
.TMAdminSearch:focus {
  border: 0;
  outline: none;
}

.TMAFilterbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.count__status__user {
  width: 75px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border: 0px;
  padding: 9px;
  background: #a4ffd4;
  margin-left: 7px;
  height: 25px;
}
.count__status__admin {
  width: 93px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border: 0px;
  padding: 9px;
  background: #a4ffd4;
  margin-left: 7px;
  height: 25px;
}
.TMDashboardOptions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-top: 15px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .TMDashboardOptions {
    display: block;
    position: relative;
  }
  .TMAdminFilterDiv select {
    width: 47%;
    position: absolute;
    box-sizing: border-box !important;
  }
  .TMAdminSearch {
    width: 46.2%;
    position: absolute;
    right: 0;
    box-sizing: border-box !important;
  }
}

.TMAdminDashboradDetailsDiv {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  justify-content: center;
  margin-top: 10px;
}

.TMAdminDashDBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
}

.TMAdminDeatilsBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  border: 2.5px solid #396e8f;
  /* border-top: 2.5px solid #396e8f; */
  width: 75% !important;
  padding: 10px;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 15%);
  border-radius: 8px;
  background: #fff;
  border: 1px solid #c7c7c7;
  transition: 0.7s;
}
.TMAdminDeatilsBox:hover {
  box-shadow: 0px 2px 8px rgb(0, 0, 0, 0.3);
  transform: scale(1.01);
}

.TMAdminDateBox {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold !important;
}

.TMAdminDateBox:hover {
  cursor: pointer;
}

.TMAdminDateCircle {
  text-align: center;
  background-color: #ade6e6;
  width: 45px;
  padding: 10px;
  border-radius: 50%;
  margin-bottom: 5px;
  color: rgb(0, 0, 0);
}

.TMAdminTitle {
  font-size: 18px;
  font-weight: 650 !important;
  margin-bottom: 10px;
}

.TMAdminRemarkSelect select {
  width: 100px;
  border: 0px;
  padding: 10px;
  background-color: #ade6e6;
  margin: 0px 5px 5px;
  border-radius: 25px;
}

.TMAdminRemarkSelect select:focus {
  outline: none;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.TMAdminDashboardDetailsMainDiv {
  overflow: scroll;
  height: 100vh;
}

.TMAdminDashboardDetailsMainDiv {
  overflow: scroll;
  height: 100vh;
}

@media only screen and (max-width: 500px) {
  .TMAdminDashboardDetailsMainDiv {
    margin-top: 60px;
  }
  .TMAdminDeatilsBox {
    width: 90% !important;
  }
}

.TMAdminDashboard__InnerMain {
  display: flex;
  flex-direction: row;
}
.TMAdminDashboard__SchoolListMain {
  display: flex;
  justify-self: center;
  width: 250px;
  margin-top: 32px;
}
.TMAdminDashboard__SchoolList {
  border: 2px solid #dbdbdb;
  position: absolute;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  height: 70vh;
  overflow: scroll;
  background: white;
  /* border-image: linear-gradient( 109.6deg,  rgba(62,161,219,1) 11.2%, rgba(93,52,236,1) 100.2% );
    border-width: 2.5px;
    border-image-slice: 1;
    border-style: solid; */
}
.TMAdminDashboard__SchoolNameCard {
  padding: 20px;
  margin: 10px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  /* border: 1.5px solid #bebebe; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}
.TMAdminDashboard__SchoolNameCard:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.TMAdminDashboard__SchoolMobileview {
  display: none;
}

.TMAD__SchoolistinMobile {
  display: none;
}

.TMAdminDashboard__SchoolNameCard1 {
  padding: 7px 20px;
  margin: 0px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  background: linear-gradient(
    109.6deg,
    rgba(62, 161, 219, 1) 11.2%,
    rgba(93, 52, 236, 1) 100.2%
  );
  color: white;
  font-weight: bold;
  border-radius: 20px;
  white-space: nowrap;
  overflow: scroll;
}
.TMAD__SchoolListPOPUPBG {
  position: absolute;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.TMAD__SchoolListPOPUP {
  display: block;
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -25%);
  margin-top: 50px;
}

@media screen and (max-width: 900px) {
  .TMAD__SchoolistinMobile {
    display: block;
  }
  .TMAdminDashboard__SchoolDisplay {
    display: none;
  }
  .TMAdminDashboard__SchoolMobileview {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 10px;
  }
  .TMAD__No_ReportsTitle {
    width: 100% !important;
  }
}
@media screen and (max-width: 900px) {
  .TMAdminDashboard__SchoolListMain {
    width: 325px;
  }
  .TMAdminDashboard__SchoolList {
    width: 280px;
  }
}
@media screen and (max-width: 345px) {
  .TMAdminDashboard__SchoolListMain {
    width: 280px;
  }
  .TMAdminDashboard__SchoolList {
    width: 235px;
  }
}

/* Task / Ticket Page Admin side */

.TMDetailsMainDiv {
  display: flex;
  flex-direction: row;
}

.TMTicketDivResponsive {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
}

.TMDetailsLeft {
  width: 30%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  border-right: 2.5px solid #396e8f;
  margin: 10px;
}

.TMDetailsLeftTop {
  margin-bottom: 100px;
  width: 95%;
}

.TMDetailsLeftTop select,
.TMDetailsLeftTop input,
.TMDetailsLeftTop a {
  width: 90%;
  background-color: #ade6e6;
  border: 0;
  outline: none;
  text-decoration: none;
  color: black;
  padding: 7px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.TMDetailsLeftTop input {
  width: 85%;
}

.TMDetailsLeftTop select:hover,
.TMDetailsLeftTop input:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.TMDetailsRight {
  width: 70%;
  position: relative;
}

.TMDetailsMessage {
  max-width: 75%;
}

.TMAdminDashboardStatus {
  width: 25%;
}

@media only screen and (max-width: 600px) {
  .TMDetailsMessage {
    max-width: 90%;
  }
  .TMAdminDashboardStatus {
    width: 50%;
    margin-bottom: 15px;
  }
}

.TMDetailsMessage div {
  width: fit-content;
  background-color: #ade6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border: 2px solid rgba(245, 245, 245, 0.3);
}

.TMDetailsRightButtons {
  position: fixed;
  width: 65.5%;
  bottom: 0;
  padding: 15px 10px;
  border-top: 1px solid lightgrey;
  transition: all 0.5s;
  background-color: white;
}

.TMDetailsRightButtons textarea {
  resize: none;
  width: 70%;
  padding: 10px;
  border: 0;
  outline: none;
  border-radius: 25px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.425);
  font-size: 17px;
  border-color: rgb(255, 255, 255) !important;
}

.TMDetailsRightButtons span {
  border-radius: 25px;
  cursor: pointer;
  font-size: 30px;
  color: #122854;
}

.TMDetailsRightButtons img {
  width: 40px;
  margin-left: 5px;
  cursor: pointer;
}

.TMDetailsRightButtons img:hover,
.TMDetailsRightButtons span:hover {
  zoom: 104%;
  transition: all 0.5s;
}

.TMDetailsDetailsButton {
  display: none !important;
}

.TMDetailsReplyButtons div {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500 !important;
}

.TMDetailsReplyButtons {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  flex-wrap: wrap;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.TMDetailsReplyButtons div:hover {
  background-color: rgba(226, 226, 226, 0.762);
  font-weight: 700 !important;
}

.TMMobileDetailsButton {
  display: none;
}

@media only screen and (min-width: 1100px) {
  .TMDetailsRight {
    width: 800px;
  }
  .TMDetailsLeft {
    width: 300px;
  }
  .TMDetailsRightButtons {
    width: 800px;
  }
}

@media only screen and (max-width: 1100px) {
  .TMTicketDivResponsive {
    display: block !important;
  }
}

@media only screen and (max-width: 500px) {
  .TMMobileDetailsButton {
    display: block;
  }
  .TMDetailsRight {
    width: 100%;
    padding: 10px;
  }
  .TMDetailsLeft {
    display: none;
  }
  .TMDetailsRightButtons {
    width: 90%;
  }
  .TMDetailsDetailsButton {
    display: flex !important;
  }
  .TMDetailsDetailsPopup {
    background-color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    overflow-y: hidden;
    transition: all 0.5s;
    height: 100vh;
  }
  .TMDetailsCloseButton {
    font-size: 30px;
    float: right;
    padding: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .TMTR__Title {
    font-size: 17.5px !important;
  }
}

/* Admin History Page */

.TMHistoryMainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TMHistoryDetailsDiv {
  display: flex;
  flex-direction: row;
}

.TMHistoryIcons {
  width: 55px;
  margin-left: -38px;
  position: absolute;
  margin-top: 12px;
  z-index: 10;
}

.TMHistoryDetailsDots {
  color: #122854;
  font-size: 12px;
  margin-top: 30px;
}

.TMHistoryDetails {
  margin-bottom: 25px;
}

.TMHistoryDetails h4 {
  margin: 5px;
}

.TMHistoryDetails span {
  margin: 5px;
  color: grey;
  font-size: 13px;
  font-weight: 500 !important;
}

/* Admin Reports Page */

.TMReportsFiltersDiv {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.425);
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

.TMReportsFiltersDiv h4 {
  margin: 5px;
}

.TMReportsFilters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.TMReportsFilters div {
  margin: 0px 10px;
}

.TMReportsFilters select,
.TMReportsFilters input {
  width: 170px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.425);
  background-color: #ade6e6;
  border: 0;
  outline: none;
  padding: 6px;
}

.TMReportFiltersHeader {
  font-size: 13px;
  font-weight: 500 !important;
  color: grey;
}

.TMReportsFilterDropDown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.TMReportsFilterDropDown img {
  width: 15px;
}

@media only screen and (max-width: 400px) {
  .TMReportsFilters select,
  .TMReportsFilters input {
    width: 200px;
  }
}

.TMreplyLeftTabStyles > h4 {
  display: block !important;
  margin-block-start: 1.33em !important;
  margin-block-end: 1.33em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  margin-top: 20px !important;
  margin-bottom: 8px !important;
}

@media only print {
  @page {
    size: A4;
  }
  body {
    zoom: 49.8%;
  }
  #hidbtn {
    display: none;
  }
}


.message{
  font-family: 'Open Sans', sans-serif;
  font-weight:'bold';
  margin-top: 10px;
}
.select-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding: 10px;
}

.select__box {
  width: 204px;
  border: none;
  height: 40px;
  background-color: #6610f2;
  color: #fff;
  border-radius: 0px;
  padding: 4px;
  margin-left: 10px;
}
.select__option {
  margin-left: 10px;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.btn__check {
  padding: 10px 60px;
  border: none;
  margin-top: 30px;
  color: #fff;
  border-radius: 0px;
  background-color: #6610f2;
}

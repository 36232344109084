.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.float_left {
  float: left;
}
.float_right {
  float: right;
}

.float_center {
  margin-left: 20%;
}

.margin-top-1 {
  margin-top: 60%;
}
.margin-top-2 {
  margin-top: 2%;
  margin-bottom: 2%;
}
.margin-top-3 {
  margin-top: 60%;
}
.myDatePicker {
  width: 100%;
}
.wrapper {
  text-align: center;
}
.yourdiv {
  display: inline-block;
}
.react-datepicker__month-container {
  float: none !important;
}
.react-datepicker {
  width: 100% !important;
}
.react-datepicker__day-name {
  margin: 2.5% !important;
  color: white !important;
}
.react-datepicker__day {
  margin: 2.5% !important;
}
.react-datepicker__header {
  background-color: #00c851 !important;
}
.react-datepicker__current-month {
  color: white !important;
}
.react-datepicker__navigation--next {
  border-left-color: white !important;
  display: none;
}
.react-datepicker__navigation--previous {
  border-right-color: white !important;
  display: none;
}
.react-datepicker__day--highlighted-custom-1 {
  background-color: #f7464a !important;
  color: white !important;
  border-radius: 5px;
}
.react-datepicker__day--highlighted-custom-2 {
  background-color: #46bfbd !important;
  color: white !important;
  border-radius: 5px;
}
.react-datepicker__day--highlighted-custom-3 {
  background-color: #fdb45c !important;
  color: white !important;
  border-radius: 5px;
}
.react-datepicker__day--highlighted-custom-4 {
  background-color: #949fb1 !important;
  color: white !important;
  border-radius: 5px;
}
.react-datepicker__day--highlighted-custom-5 {
  background-color: #4d5360 !important;
  color: white !important;
  border-radius: 5px;
}
.react-datepicker__current-month {
  display: none;
}
.date_header {
  padding: 0px 0px 5px 0px !important;
}
.date_display_btn {
  margin-bottom: 2px;
}
.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  font-size: 0.8rem;
}
.font-weight-bold {
  font-weight: 700 !important;
  font-size: 0.9rem;
}
.react-datepicker__day {
  cursor: none !important;
}
.dateEvent {
  padding: 0px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-background {
  background-color: lightyellow !important;
}

.collapsecard {
  display: none;
}

.collapsecard.in {
  display: block;
}
.btn-rounded {
  border-radius: 20px !important;
}
.card-margin {
  clear: both;
}

.Calender-App{
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
}

/* Loader */

/* Loader container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust this as needed */
}

/* Loader animation */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #1a73e8; /* Loader color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* Loader animation keyframes */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
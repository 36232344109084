* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  color: black;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}
.label__title {
  color: black;
  font-size: 16px;
  margin-top: 6px;
  font-weight: 700;
  font-family: 'Courier New', Courier, monospace;
}
/* div {
  margin: 5px 0px;
} */
.form {
  margin: 10px 15px;
}
.form-main {
  margin: 0px 20px;
  margin-top: 20px;
}
.form-inputs {
  display: flex;
}
.lesson-heading {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  background-color: white;
}
.lesson-heading > h3 {
  color: #1565c0;
  margin-top: 8px;
  font-size: 24px;
}
.form-details,
.form-subject-details {
  display: flex;
}
.form-details > div {
  width: 100%;
}
.user-details {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 18px;
}
@media screen and (max-width: 600px) {
  .form-details,
  .form-subject-details {
    margin: 0px;
    flex-direction: column;
  }
  .form-details > div {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .form-subject-details > div {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .form {
    margin: 10px 0px;
  }
}
.form-supplemental {
  margin-top: 20px;
  margin-bottom: 5px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 14px;
}
.mid-details {
  margin-top: 20px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 14px;
}
.mid-details > div > label {
  margin-left: 5px;
}
.btn-div {
  display: flex;
  justify-content: center;
}

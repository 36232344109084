.info__bar{
  display: flex;
  justify-content: space-between;
  align-items:center;
  border-bottom: 2px solid skyblue ;
  font-family: sans-serif;
  text-align: center;
  width: 100%;
}
.info__bar p{
  width: 25%;
}


.info__bar p:nth-child(1){
  margin-left: 0.5rem;
}



.logo__main{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}